import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import Title from "../components/Title";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faMobileAlt,
  faEnvelope,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";

const StyledCol = styled(Col)``;

const ContactList = styled.ul`
  display: flex;
  flex-direction: column;
  line-height: 2rem;
`;

const ContactItem = styled.li`
  font-size: 1.5rem;
  padding: 0;
  margin-bottom: 0.5rem;
  > p {
    margin: 0;
  }
  > svg {
    top: 0.2rem;
  }
`;

const MailLink = styled.a`
  color: #428bca;
  text-decoration: none;
`;

const ContactPage = () => {
  const data = useStaticQuery(query);
  return (
    <Layout pageTitle="Kontakt">
      <Title>Kontakt</Title>
      <Row className="justify-content-center w-100">
        <StyledCol md="auto">
          <ContactList className="fa-ul">
            <ContactItem>
              <FontAwesomeIcon icon={faMapMarkerAlt} listItem />
              <ReactMarkdown>{data.strapiKontakt.Adres}</ReactMarkdown>
            </ContactItem>
            <ContactItem>
              <FontAwesomeIcon icon={faMobileAlt} listItem />
              {data.strapiKontakt.Telefon1}
            </ContactItem>
            <ContactItem>
              <FontAwesomeIcon icon={faMobileAlt} listItem />
              {data.strapiKontakt.Telefon2}
            </ContactItem>
            <ContactItem>
              <FontAwesomeIcon icon={faEnvelope} listItem />
              <MailLink href={`mailto:${data.strapiKontakt.Email}`}>
                {data.strapiKontakt.Email}
              </MailLink>
            </ContactItem>
            <ContactItem>
              <FontAwesomeIcon icon={faUniversity} listItem />
              {data.strapiKontakt.NumerKonta}
            </ContactItem>
          </ContactList>
        </StyledCol>
      </Row>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiKontakt {
      Telefon1
      NumerKonta
      Email
      Adres
      Telefon2
    }
  }
`;

export default ContactPage;
